<template>
  <div class="pay_result" :style="{'minHeight':cHeight}">
    <div class="header"><span>支付结果</span></div>
    <div class="main">
      <img src="../assets/icon_success.png" alt="" v-if="state=='success'||state=='upload'">
      <img src="../assets/icon_fail.png" alt="" v-if="state=='fail'">
      <div class="title" v-if="state=='success'">支付成功</div>
      <div class="title" v-if="state=='upload'">上传成功</div>
      <div class="title" v-if="state=='fail'">支付失败</div>
      <div class="tip" v-if="info.productType==0&&state=='success'">
        <div>律师将在24小时内给您回电，</div>
        <div>请注意接听950开头的电话~</div>
      </div>
      <div class="tip" v-if="info.productType==1&&state=='success'">
        <div>已为您开通会员套餐，可联系法务助理了解使用教程</div>
        <div>如需快速处理，您可联系法务助理</div>
      </div>
      <div class="tip" v-if="state=='upload'">
        <div>您已上传支付凭证，将在1个工作日内审核完成</div>
        <div>如需快速处理，您可联系法务助理</div>
      </div>
      <div class="btn" v-if="state=='upload'||(info.productType==1&&state=='success')"><a
          :href="'tel:'+info.safeNumber">联系法务助理</a></div>
      <div class="btn fail" v-if="state=='fail'" @click="goPay">重新支付</div>
    </div>
    <div class="foot">由无锡中铠信息咨询服务有限公司提供技术支持</div>
  </div>
</template>

<script>
  import {
    GetPayProductInfo
  } from '@/api'
  export default {
    data() {
      return {
        cHeight: null,
        state: '',
        info: {}
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      document.title = '支付申请'
      this.state = this.$route.query.type
      GetPayProductInfo({
        "orderCustomerId": localStorage.getItem('orderCustomerId')
      }).then(res => {
        if (res.state == 0) {
          var info = res.data
          this.info = info
          console.log(this.info)
        } else {
          // this.$toast(res.data)
        }
      })
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL);
      });
    },
    methods: {
      goPay() {
        this.$router.replace('pay_cashier')
      },

    }
  }

</script>
<style scoped lang='scss'>
  .pay_result {
    background: #F4F4F4;

    .header {
      text-align: center;
      padding-top: 20px;
      font-size: 30px;
      color: #666;
      position: relative;

      img {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 12px;
        left: 22px;
      }
    }

    .main {
      text-align: center;
      margin-top: 240px;

      >img {
        width: 140px;
        height: 140px;
        margin-bottom: 40px;
      }

      .title {
        font-size: 40px;
        color: #333333;
        margin-bottom: 32px;
      }

      .tip {
        font-size: 30px;
        color: #999999;
      }

      .btn {
        width: 520px;
        height: 80px;
        background: #1387FF;
        border-radius: 40px;
        font-size: 34px;
        color: #FFFFFF;
        text-align: center;
        line-height: 80px;
        margin: 50px auto 0;

        a {
          color: #FFFFFF;
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .fail {
        margin-top: 98px;
      }
    }

    .foot {
      width: 100%;
      text-align: center;
      font-size: 22px;
      color: #999999;
      position: absolute;
      bottom: 36px;
      left: 0;
    }

  }

</style>
<style lang='scss'>


</style>
